import React from 'react';
import { navigate, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  standardColours,
  fontSize,
  fontWeights,
  minBreakpointQuery,
  standardTransition,
} from '../styles';
import { Link } from './ui';

// moved up for hover
const StyledImage = styled(GatsbyImage)`
  transition: ${standardTransition('transform')};
`;

const StyledBlogCard = styled.article`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 30px; // refactor
  overflow: hidden; // refactor

  &:hover {
    ${StyledImage} {
      transform: scale(1.1);
    }
  }
`;

const StyledIntro = styled.div`
  background-color: ${({ color }) => color};
  color: ${standardColours.white};
  padding: 35px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  ${minBreakpointQuery.mlarge`
    padding: 40px 25px;
  `}

  ${minBreakpointQuery.large`
    padding: 40px 35px;
  `}
`;

const StyledHeading = styled.h3`
  ${fontSize(25)};
  font-weight: ${fontWeights.regular};

  ${minBreakpointQuery.large`
    ${fontSize(30)};
  `}
`;

const StyledPublishedDate = styled.p`
  margin-top: 20px;

  ${minBreakpointQuery.smedium`
    margin-top: 30px;
  `}

  ${minBreakpointQuery.medium`
    margin-top: 40px;
  `}

  ${minBreakpointQuery.mlarge`
    margin-top: 50px;
  `}
`;

const StyledImageWrapper = styled.div`
  overflow: hidden;
`;

const BlogCard = ({
  heading,
  image,
  firstPublishedAt,
  slug,
  color,
  ...props
}) => {
  const urlPath = `/blog/${slug}`;

  return (
    <StyledBlogCard onClick={() => navigate(urlPath)} {...props}>
      <StyledIntro color={color}>
        <StyledHeading>
          <Link to={urlPath}>{heading}</Link>
        </StyledHeading>
        <StyledPublishedDate>{firstPublishedAt}</StyledPublishedDate>
      </StyledIntro>
      <StyledImageWrapper>
        <StyledImage image={image.gatsbyImageData} alt={image.alt} />
      </StyledImageWrapper>
    </StyledBlogCard>
  );
};

export default BlogCard;

export const BlogCardFragment = graphql`
  fragment BlogCardFragment on DatoCmsBlog {
    title
    slug
    featuredImage {
      gatsbyImageData(width: 560, height: 280)
      alt
    }
    meta {
      firstPublishedAt(formatString: "DD MMM YYYY")
    }
  }
`;
