import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import BlogListing from '../components/BlogListing';

const BlogArchive = () => {
  const {
    datoCmsBlogArchive: {
      seoMetaTags,
      title,
      bannerImage,
      bannerText,
      bannerDarkOverlayOpacity,
    },
    allDatoCmsBlog: { nodes },
  } = useStaticQuery(graphql`
    query BlogArchiveQuery {
      datoCmsBlogArchive {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        title
        bannerImage {
          ...SubPageBannerImageFragment
        }
        bannerText
        bannerDarkOverlayOpacity
      }
      allDatoCmsBlog(sort: { order: DESC, fields: meta___firstPublishedAt }) {
        nodes {
          ...BlogCardFragment
        }
      }
    }
  `);

  return (
    <Layout seo={seoMetaTags}>
      <main>
        <Banner
          heading={title}
          image={bannerImage}
          text={bannerText}
          darkOverlayOpacity={bannerDarkOverlayOpacity}
        />
        <BlogListing items={nodes} isBlogArchive={true} />
      </main>
    </Layout>
  );
};

export default BlogArchive;
