import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { standardColours, fontSize } from '../styles';
import { Link } from './ui';
import { buildUrl } from '../utils';

const StyledBreadcrumbs = styled.div`
  position: relative;
  display: inline-block;
`;

const StyledList = styled.ol`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledItem = styled.li`
  display: flex;
  align-items: center;
  color: ${standardColours.white};
  ${fontSize(12)};
  white-space: nowrap;
`;

const StyledLink = styled(Link)`
  opacity: 0.8;

  &:after {
    content: '/';
    margin: 0 5px;
  }
`;

const Breadcrumbs = ({ currentPageTitle, items = [], ...props }) => {
  if (items) {
    if (!Array.isArray(items)) {
      const treeParent = items;
      items = [];

      items.unshift({
        text: treeParent.title,
        urlPath: buildUrl(treeParent.slug, treeParent),
      });

      if (treeParent.treeParent) {
        items.unshift({
          text: treeParent.treeParent.title,
          urlPath: buildUrl(treeParent.treeParent.slug, treeParent.treeParent),
        });

        if (treeParent.treeParent.treeParent) {
          items.unshift({
            text: treeParent.treeParent.treeParent.title,
            urlPath: buildUrl(
              treeParent.treeParent.treeParent.slug,
              treeParent.treeParent.treeParent
            ),
          });
        }
      }
    }
  } else {
    items = [];
  }

  items.unshift({ text: 'Home', urlPath: '/' });
  items.push({ text: currentPageTitle });

  return (
    items && (
      <>
        <Helmet
          script={[
            {
              type: 'application/ld+json',
              innerHTML: `{
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                  ${items.map(({ text, urlPath }, i) =>
                    JSON.stringify({
                      '@type': 'ListItem',
                      position: i + 1,
                      name: text,
                      ...(urlPath && {
                        item: process.env.GATSBY_SITE_URL + buildUrl(urlPath),
                      }),
                    })
                  )}
                ]
              }`,
            },
          ]}
        />
        <StyledBreadcrumbs {...props}>
          <StyledList>
            {items.map(({ text, urlPath }, id) => (
              <StyledItem key={id}>
                {urlPath ? <StyledLink to={urlPath}>{text}</StyledLink> : text}
              </StyledItem>
            ))}
          </StyledList>
        </StyledBreadcrumbs>
      </>
    )
  );
};

export default Breadcrumbs;
