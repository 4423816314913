import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  brandColours,
  fontWeights,
  fontSize,
} from '../styles';
import { Container } from './ui';

const StyledIconUsps = styled.section`
  border-bottom: 1px solid ${brandColours.quaternary};
`;

const StyledItems = styled.ul`
  display: flex;
  align-items: center;
  padding: 20px 0;

  ${maxBreakpointQuery.mlarge`
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
  `}

  ${minBreakpointQuery.mlarge`
    justify-content: space-between;
  `}

  ${minBreakpointQuery.large`
    padding-top: 25px;
    padding-bottom: 25px;
  `}
`;

const StyledItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  min-width: 100%;

  ${maxBreakpointQuery.mlarge`
    scroll-snap-align: start;
  `}

  ${minBreakpointQuery.tsmall`
    min-width: 50%;
  `}

  ${minBreakpointQuery.smedium`
    min-width: calc(100% / 3);
  `}

  ${minBreakpointQuery.mlarge`
    min-width: 25%;
  `}
`;

const StyledIcon = styled.img`
  width: 40px;

  ${minBreakpointQuery.small`
    width: 45px;
  `}

  ${minBreakpointQuery.large`
    width: 50px;
  `}
`;

const StyledText = styled.p`
  margin-left: 14px;
`;

const StyledName = styled.strong`
  display: block;
  font-weight: ${fontWeights.regular};
  text-transform: uppercase;
`;

const StyledDescription = styled.span`
  margin-top: 2px;
  ${fontSize(12)};
`;

const IconUsps = ({ items }) => {
  const {
    datoCmsIconUspsSitewide: { iconUsps },
  } = useStaticQuery(graphql`
    query IconUspsQuery {
      datoCmsIconUspsSitewide {
        iconUsps {
          ...IconUspFragment
        }
      }
    }
  `);

  return (
    <StyledIconUsps>
      <Container>
        <StyledItems>
          {(items || iconUsps).map(({ id, icon, name, description }) => (
            <StyledItem key={id}>
              <StyledIcon src={icon.url} alt={icon.alt} loading="lazy" />
              <StyledText>
                <StyledName>{name}</StyledName>
                {description && (
                  <StyledDescription>{description}</StyledDescription>
                )}
              </StyledText>
            </StyledItem>
          ))}
        </StyledItems>
      </Container>
    </StyledIconUsps>
  );
};

export default IconUsps;

export const IconUspFragment = graphql`
  fragment IconUspFragment on DatoCmsIconUsp {
    id
    icon {
      url
      alt
    }
    name
    description
  }
`;
