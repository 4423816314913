import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  sectionPaddings,
  standardColours,
  fontWeights,
  fontSize,
  fluidFontSize,
} from '../styles';
import { Container, Button } from './ui';
import Breadcrumbs from './Breadcrumbs';
import IconUsps from './IconUsps';

const StyledBanner = styled.section`
  position: relative;
`;

const StyledImage = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
`;

const StyledInner = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  ${sectionPaddings('80px', '140px')};
  min-height: calc(100vw / 4);
  background-color: ${({ darkOverlayOpacity }) =>
    darkOverlayOpacity
      ? standardColours.transparentBlack(darkOverlayOpacity / 100)
      : standardColours.transparentBlack(0.15)};

  ${minBreakpointQuery.xxxxlarge`
    min-height: ${({ isHomepage }) => (isHomepage ? '650' : '500')}px;
  `}
`;

const StyledContent = styled.div`
  width: 100%;
  color: ${standardColours.white};
  text-align: center;
`;

const StyledHeading = styled.h1`
  font-weight: ${fontWeights.regular};
  line-height: 1.1;
  text-transform: uppercase;

  ${({ isHomepage }) => {
    if (isHomepage) {
      return css`
        ${fluidFontSize(
          '20px',
          '30px',
          breakpointSizes.tiny,
          breakpointSizes.xxxxlarge
        )};
        letter-spacing: 7px;

        strong {
          display: block;
          font-weight: ${fontWeights.regular};
          ${fluidFontSize(
            '60px',
            '150px',
            breakpointSizes.tiny,
            breakpointSizes.xxxxlarge
          )};
        }
      `;
    } else {
      return css`
        ${fluidFontSize(
          '35px',
          '70px',
          breakpointSizes.tiny,
          breakpointSizes.xxxxlarge
        )};
        letter-spacing: 3.5px;
      `;
    }
  }}
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-top: 10px;
`;

const StyledText = styled.p`
  margin: 16px auto 0;
  max-width: 600px;
  line-height: 1.5;

  ${minBreakpointQuery.small`
    margin-top: 20px;
    ${fontSize(17)};
  `}

  ${minBreakpointQuery.large`
    margin-top: 24px;
    ${fontSize(18)};
  `}
`;

const StyledButton = styled(Button)`
  margin-top: 16px;

  ${minBreakpointQuery.small`
    margin-top: 20px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 24px;
  `}
`;

const Banner = ({
  heading,
  image,
  text,
  link,
  breadcrumbs,
  darkOverlayOpacity,
  isHomepage,
}) => (
  <>
    <StyledBanner>
      <StyledImage image={image.gatsbyImageData} alt={image.alt} />
      <StyledInner
        darkOverlayOpacity={darkOverlayOpacity}
        isHomepage={isHomepage}
      >
        <StyledContent>
          <Container narrow={true}>
            <StyledHeading
              dangerouslySetInnerHTML={{ __html: heading }}
              isHomepage={isHomepage}
            />
            {!isHomepage && (
              <StyledBreadcrumbs
                currentPageTitle={heading}
                items={breadcrumbs}
              />
            )}
            {text && <StyledText>{text}</StyledText>}
            {link && (
              <StyledButton to={link.page} alt={true}>
                {link.text}
              </StyledButton>
            )}
          </Container>
        </StyledContent>
      </StyledInner>
    </StyledBanner>
    <IconUsps />
  </>
);

export default Banner;

export const BannerImageFragment = graphql`
  fragment HomePageBannerImageFragment on DatoCmsFileField {
    gatsbyImageData(width: 2000, height: 650, layout: FULL_WIDTH)
    alt
  }

  fragment SubPageBannerImageFragment on DatoCmsFileField {
    gatsbyImageData(width: 2000, height: 500, layout: FULL_WIDTH)
    alt
  }
`;
