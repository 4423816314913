import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  sectionMargins,
  standardColours,
  brandColours,
  fontSize,
  visuallyHidden,
} from '../styles';
import { Container, Link, Svg } from './ui';
import BlogCard from './BlogCard';
import blackmoorIcon from '../images/brands/blackmoor/icon.inline.svg';
import questIcon from '../images/brands/quest/icon.inline.svg';
import arrowIcon from '../images/arrow.inline.svg';

const StyledBlogListing = styled.section`
  ${sectionMargins()};
`;

const StyledHeading = styled.h2`
  ${visuallyHidden()};
`;

const StyledItems = styled.div`
  display: grid;
  gap: 25px 20px;

  ${minBreakpointQuery.small`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.mlarge`
    grid-template-columns: repeat(3, 1fr);
  `}
`;

const StyleBlogCard = styled(BlogCard)``;

const StyledViewAll = styled(Link)`
  background-color: ${standardColours.grey};
  min-height: 350px;
  width: 100%;
  padding: 35px 20px;
  ${fontSize(30)};
  display: flex;
  position: relative;
  border-radius: 30px; // refactor
  overflow: hidden;

  ${minBreakpointQuery.small`
    grid-column: 1 / 3;
  `}

  ${minBreakpointQuery.mlarge`
    grid-column: auto;
  `}
`;

const StyledIcon = styled(Svg)`
  width: 255px;
  height: 255px;
  position: absolute;
  top: -45px;
  left: -65px;
`;

const StyledText = styled.span`
  margin-top: auto;
  max-width: 150px;
`;

const StyledArrow = styled(Svg)`
  position: absolute;
  right: 25px;
  top: 35px;
  width: 30px;
  height: 20px;

  ${minBreakpointQuery.large`
    right: 35px;
    top: 45px;
  `}
`;

const BlogListing = ({ items, isBlogArchive }) => {
  const {
    allDatoCmsBlog: { nodes },
  } = useStaticQuery(graphql`
    query BlogListingQuery {
      allDatoCmsBlog(
        limit: 2
        sort: { order: DESC, fields: meta___firstPublishedAt }
      ) {
        nodes {
          ...BlogCardFragment
        }
      }
    }
  `);

  const alternatingColor = [brandColours.primary, brandColours.secondary];
  const posts = items || nodes;
  let icon;

  switch (process.env.GATSBY_BENROSS_BRAND) {
    case 'BLACKMOOR':
      icon = blackmoorIcon;
      break;
    case 'QUEST':
      icon = questIcon;
      break;
  }

  return (
    <StyledBlogListing>
      <Container>
        <StyledHeading>Recent Blog Posts</StyledHeading>
        <StyledItems>
          {posts.map(
            (
              { id, title, featuredImage, slug, meta: { firstPublishedAt } },
              i
            ) => (
              <StyleBlogCard
                key={id}
                heading={title}
                image={featuredImage}
                firstPublishedAt={firstPublishedAt}
                slug={slug}
                color={alternatingColor[i % alternatingColor.length]}
              />
            )
          )}
          {!isBlogArchive && (
            <StyledViewAll to="blog">
              <StyledIcon image={icon} />
              <StyledText>View all Blog Posts</StyledText>
              <StyledArrow image={arrowIcon} />
            </StyledViewAll>
          )}
        </StyledItems>
      </Container>
    </StyledBlogListing>
  );
};

export default BlogListing;
